<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <logo />
        <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
      </b-link>

      <b-navbar-nav class="nav align-items-center ml-auto absolute-locale">
        <locale></locale>
      </b-navbar-nav>
    </b-row>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("pageNotFound.title") }}</h2>
        <p class="mb-2">
          {{ $t("pageNotFound.subTitle") }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          {{ $t("button.backToHome") }}
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BRow, BNavbarNav } from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";
import Locale from "@/layouts/components/app-navbar/Locale.vue";

export default {
  components: {
    BLink,
    BButton,
    BImg,
    BRow,
    BNavbarNav,

    Logo,
    Locale,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  setup() {
    const { appName } = $themeConfig.app;

    return {
      appName,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
